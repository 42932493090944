module.exports = {
  siteTitle: 'GowthamLingala|Software Engineer',
  siteDescription:
    'Gowtham Lingala is a software engineer based in Hyderabad, India studied at K.L University with a keen interest in Building & Scaling Software, Product Management and Community Building',
  siteKeywords:
    "Gowtham Lingala, Gowtham, Lingala, lingalagowtham, software engineer, servicenow developer, devops, community building, product, management, javascript, python, KLU, Hyderabad, India",
  siteUrl: 'https://gowthamlingala.me',
  siteLanguage: "en_US",
  // googleAnalyticsID: 'UA-164593019-1',
  // googleVerification: 'DCl7VAf9tcz6eD9gb67NfkNnJ1PKRNcg8qQiwpbx9Lk',
  name: "Gowtham Lingala",
  location: "Hyderabad, India",
  email: "gowtham.lingala@gmail.com",
  github: "https://github.com/GOVTHAM",
  twitterHandle: "@gowthamlingala",
  socialMedia: [
    {
      name: "GitHub",
      url: "https://github.com/GOVTHAM",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/gowthamlingala",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/gowtham.lingala/",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/GowthamLingala",
    },
  ],

  navLinks: [
    {
      name: "About",
      url: "/#about",
    },
    {
      name: "Experience",
      url: "/#jobs",
    },
    // {
    //   name: 'Work',
    //   url: '/#projects',
    // },
    {
      name: "Contact",
      url: "/#contact",
    },
    // {
    //   name: 'Blog',
    //   url: '/blog',
    // },
  ],

  navHeight: 100,
  colors: {
    green: "#e15729",
    navy: "#17113d",
    darkNavy: "#201a4b",
  },

  srConfig: (delay = 200) => ({
    origin: "bottom",
    distance: "20px",
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    mobile: true,
    reset: false,
    useDelay: "always",
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
