import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(30.000000, 30.000000)">
        <path
          // d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          // d="M24.0977 22.6426C23.2305 23.8848 22.0176 24.8164 20.459 25.4375C18.9121 26.0469 17.1074 26.3516 15.0449 26.3516C12.959 26.3516 11.1074 25.8652 9.49023 24.8926C7.87305 23.9082 6.61914 22.5137 5.72852 20.709C4.84961 18.9043 4.39844 16.8125 4.375 14.4336V12.2012C4.375 8.3457 5.27148 5.35742 7.06445 3.23633C8.86914 1.11523 11.4004 0.0546875 14.6582 0.0546875C17.3301 0.0546875 19.4805 0.740234 21.1094 2.11133C22.7383 3.4707 23.7344 5.4043 24.0977 7.91211H20.7227C20.0898 4.52539 18.0742 2.83203 14.6758 2.83203C12.4141 2.83203 10.6973 3.62891 9.52539 5.22266C8.36523 6.80469 7.7793 9.10156 7.76758 12.1133V14.2051C7.76758 17.0762 8.42383 19.3613 9.73633 21.0605C11.0488 22.748 12.8242 23.5918 15.0625 23.5918C16.3281 23.5918 17.4355 23.4512 18.3848 23.1699C19.334 22.8887 20.1191 22.4141 20.7402 21.7461V15.998H14.8164V13.2559H24.0977V22.6426Z"
          // d="M17.5322 25.64C18.7082 25.64 19.4522 25.424 19.7642 24.992C20.1002 24.56 20.2682 23.828 20.2682 22.796V15.812H17.9642V15.02H28.3322V15.812H26.9282V25.964H26.3882C25.9562 25.364 25.3442 25.064 24.5522 25.064C24.2162 25.064 23.2082 25.28 21.5282 25.712C19.8722 26.144 18.4562 26.36 17.2802 26.36C13.3682 26.36 10.3322 25.304 8.17219 23.192C6.03619 21.08 4.96819 17.912 4.96819 13.688C4.96819 9.44 6.08419 6.164 8.31619 3.86C10.5722 1.556 13.5962 0.403999 17.3882 0.403999C18.8522 0.403999 20.3162 0.595999 21.7802 0.979998C23.2682 1.34 24.1322 1.52 24.3722 1.52C24.6122 1.52 24.7922 1.472 24.9122 1.376C25.0322 1.256 25.1642 1.064 25.3082 0.799998H25.7042L25.9922 8.612H25.3442C24.1682 6.284 22.9802 4.46 21.7802 3.14C20.6042 1.796 19.2842 1.124 17.8202 1.124C14.1482 1.124 12.3122 4.712 12.3122 11.888V14.732C12.3122 18.332 12.7562 21.056 13.6442 22.904C14.5562 24.728 15.8522 25.64 17.5322 25.64Z"
          d="M20.8763 34.52C22.4443 34.52 23.4363 34.232 23.8523 33.656C24.3003 33.08 24.5243 32.104 24.5243 30.728V21.416H21.4523V20.36H35.2763V21.416H33.4043V34.952H32.6843C32.1083 34.152 31.2923 33.752 30.2363 33.752C29.7883 33.752 28.4443 34.04 26.2043 34.616C23.9963 35.192 22.1083 35.48 20.5403 35.48C15.3243 35.48 11.2763 34.072 8.39625 31.256C5.54825 28.44 4.12425 24.216 4.12425 18.584C4.12425 12.92 5.61225 8.552 8.58825 5.48C11.5963 2.408 15.6283 0.871999 20.6843 0.871999C22.6363 0.871999 24.5883 1.128 26.5403 1.64C28.5243 2.12 29.6763 2.36 29.9963 2.36C30.3163 2.36 30.5563 2.296 30.7163 2.168C30.8763 2.008 31.0523 1.752 31.2443 1.4H31.7723L32.1562 11.816H31.2923C29.7243 8.712 28.1403 6.28 26.5403 4.52C24.9723 2.728 23.2123 1.832 21.2603 1.832C16.3643 1.832 13.9163 6.616 13.9163 16.184V19.976C13.9163 24.776 14.5083 28.408 15.6923 30.872C16.9083 33.304 18.6363 34.52 20.8763 34.52Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
